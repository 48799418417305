.mat-dialog-content .vt-form-label {
    width: 40%;
    display: inline-block;
}

.mat-dialog-content mat-form-field.full-width {
    width: 60%;
}

.mat-dialog-content mat-form-field.small {
    width: 20%;
}
.mat-dialog-content mat-form-field.medium {
    width: 40%;
}