/* You can add global styles to this file, and also import other style files */
@import 'assets/scss/common/colors.scss';
@import 'assets/scss/common/grid.scss';
@import 'assets/scss/common/tabs.scss';
@import 'assets/scss/common/modal.scss';
@import 'assets/scss/common/sizes.scss';
@import 'assets/scss/common/panels.scss';
@import 'assets/scss/common/cards.scss';

* {
  box-sizing: border-box;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

html {
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: none;
  overflow: auto;
}

img {
  max-width: 100%;
}

body .mat-tab-body-content {
  overflow: hidden;
}

//text color to dark blue
body .mat-tab-body-content, .mat-card {
    color: $darkBlue;
}

.mat-cell, .mat-footer-cell {
  color: $darkBlue;
}

.mat-progress-spinner svg {
  width: 30px;
  height: 30px;
  padding: 5px;
}

.mat-spinner-container {
  position: relative;
}

.mat-spinner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mat-progress-spinner.mat-spinner-background {
  position: absolute;
  top: 0;
  left: 0;
}

.margin-tab-group mat-tab-header {
  margin-bottom: 10px;
}

textarea {
  vertical-align: top !important;
  resize: none !important;
}

.mat-card-actions {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.error-snack-bar {
  background-color: $orange;

  .mat-simple-snackbar-action,
  .mat-simple-snackbar {
    color: $white;
  }
}

.success-snack-bar {
  background-color: $green;

  .mat-simple-snackbar-action,
  .mat-simple-snackbar {
    color: $white;
  }
}

.full-width {
  width: 100vw;
}

.pointer {
  cursor: pointer;
}

.zoom-in {
  cursor: zoom-in;
}

mat-error:not(:first-of-type) {
  display: none;
}

button {
  border-radius: 0;
  &[disabled],
  &:disabled {
    background: transparent;
    opacity: 1;
    color: $disabledGrey;
    .material-icons {
      color: $disabledGrey;
    }

    &:hover {
      color: lighten($grey, 30%);
      .material-icons {
        color: lighten($grey, 30%);
      }
    }
  }

  &.red {
    &:hover {
      color: $red;
      .material-icons {
        color: $red;
      }
    }
  }

  &.green {
    &:hover {
      color: $green;
      .material-icons {
        color: $green;
      }
    }
  }

  &.blue {
    &:hover {
      color: $blue;
      .material-icons {
        color: $blue;
      }
    }
  }

  &.orange {
    &:hover {
      color: $orange;
      .material-icons {
        color: $orange;
      }
    }
  }
}

.detail-row {
  display: flex;
  margin: {
    left: -16px;
    right: -16px;
  }

  .detail-col {
    width: 50%;
    padding: {
      left: 16px;
      right: 16px;
    }

    .detail-col-center {
      top: 50%;
      left: 50%;
      position: relative;
      transform: translate(-50%, -50%);

      .detail-col-center-row {
        padding: {
          top: 5px;
          bottom: 5px;
        }
      }
    }
  }
}

.mat-form-field-label-wrapper {
  display: none;
}

.block-filter,
.login-card {
  .mat-form-field-label-wrapper {
    display: block;
  }
}

.mat-drawer-content {
  background-color: $white;
}

.mat-card.block-header {
  display: flex;
  align-items: flex-start;
  box-shadow: none;
  .mat-card-title {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  .block-header__subtitle {
    display: block;
    color: $grey;
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: 0;
    margin-left: 5px;
  }
  .block-header__actions {
    margin-left: auto;
  }
}

.block-header__actions,
.mat-column-actions {
  button {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    background-color: transparent;
    .material-icons {
      font-size: 28px;
      color: $icon-grey;
    }

    &.red {
      &:hover {
        color: $orange;
        .material-icons {
          color: $orange;
        }
      }
    }

    &.green {
      .material-icons {
        color: $green;
      }
      &:hover {
        color: $green;
      }
    }

    &.blue {
      &:hover {
        color: $blue;
        .material-icons {
          color: $blue;
        }
      }
    }

    &.orange {
      &:hover {
        color: $orange;
        .material-icons {
          color: $orange;
        }
      }
    }
  }
}

.table-push {
    padding-left: 200px;
}

.mat-dialog-container {
  .mat-dialog-actions {
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.cdk-overlay-pane > .mat-select-panel {
  max-width: 350px;
}

.default-theme {
  .mat-action-row {
    padding: 10px 8px 10px 24px;
  }

  .mat-button,
  .mat-flat-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-raised-button {
    border-radius: 0;
    line-height: 42px;
    min-width: 100px;

    &.icon-only,
    &.mat-icon-button {
      min-width: 0;
    }

    &.mat-primary {
      background-color: #e4e4e4;
      color: $grey;
    }
  }

  .mat-button-wrapper {
    .material-icons {
      margin-right: 3px;

      .icon-only & {
        margin-right: 0;
      }
    }
  }

  .icon-only .mat-button-wrapper .material-icons {
    margin-right: 0;
  }

  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: $green;
  }

  .mat-option:hover:not(.mat-option-disabled),
  .mat-option:focus:not(.mat-option-disabled) {
    background: lighten($grey, 45%);
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-flex {
    border: 1px solid lighten($grey, 45%);
    padding: 0.5em;
    margin-top: 5px;
  }

  .mat-form-field-invalid {
    .mat-form-field-flex {
      border-color: $orange;
    }
  }

  .mat-focused {
    .mat-form-field-flex {
      border-color: $blue;
    }
  }

  .mat-form-field-infix {
    border-top: 0;
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.4em) scale(0.9) perspective(100px) translateZ(0.001px);
    background-color: $white;
    width: auto;
  }

  .grid-header .mat-grid-tile .mat-figure {
    align-items: baseline;
  }

  .mat-row.pointer {
    &:hover {
      background-color: lighten($grey, 50%);
    }
    &:focus {
      outline: none;
    }
    &:active {
      background-color: lighten($grey, 45%);
    }
  }

  .mat-form-field-label-wrapper {
    overflow: unset;
  }

  .mat-sidenav-container[_ngcontent-c0] {
    background-color: $white;
  }

  .whitelabel-filter {
    button.reset-button {
      margin-top: 5px;
    }

    .mat-spinner {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
      pointer-events: none;
    }
  }

  .block-filter {
    padding: {
      left: 16px;
      right: 16px;
    }
    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }

  .footer-actions {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    margin-left: 200px;

    &.wizard {
      margin-left: 40px;
    }

    &.summary {
      margin-left: 0px;
    }

    button {
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  .mat-column-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .mat-flat-button {
      font-size: 14px;
      height: auto;
      .material-icons {
        font-size: 24px;
      }
    }

    button {
      padding: 0;
      min-width: 0;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  .form-group {
    display: flex;
    align-items: center;
    padding-bottom: 1.34375em;

    .vt-form-label {
      width: 200px;
      display: inline-block;
      flex: 0 0 auto;
    }

    .mat-form-field {
      flex: 1 1 auto;
    }

    .mat-form-field-flex {
      margin-top: 0;
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;
    }

    &--align-top {
      align-items: baseline;
    }
  }

  .push-left {
    margin-left: auto;
  }

  .push-right {
    margin-right: auto;
  }

  .table-big {
    mat-row {
      padding: 15px 0;
    }
    button {
      font-size: 20px;
      padding: 2px 20px;
    }
    .mat-column-actions {
      button {
        font-size: 20px;
        padding: 2px 20px;
      }
    }
  }

  .mat-form-field-hide-placeholder {
    .mat-select-placeholder {
      color: $grey;
      -webkit-text-fill-color: unset;
    }
  }

  .isloading {
    padding-right: 42px;
    .mat-spinner {
      position: absolute;
      right: 10px;
      top: 6px;
      circle {
        stroke: white;
      }
    }
  }

  .isloadingBlue {
    padding-right: 42px;
    .mat-spinner {
      display: inline-block;
      right: 10px;
      circle {
        stroke: $blue;
      }
    }
  }
}

.cdk-overlay-pane {
  display: block;
}

.mat-footer-row {
  font-weight: bold;
}

body {
  .mat-tab-body-wrapper {
    overflow: visible;
  }
  .mat-tab-body.mat-tab-body-active {
    overflow: visible;
    .mat-tab-body-content {
      overflow: visible;
    }
  }
}

.cards-holder {
  padding: 20px;
  .mat-card {
    margin-bottom: 30px;
  }
}

.mat-card-header {
  &.content-header {
    .mat-card-title {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}

.form-value-green {
  color: $green !important;
  font-weight: bold !important;

  .mat-select-value {
    color: $green !important;
    font-weight: bold !important;
  }
}

.form-value-black {
  color: $black !important;

  .mat-select-value {
    color: $black !important;
  }
}

.mat-checkbox.mat-checkbox-large .mat-checkbox-inner-container {
  height: 22px;
  width: 22px;
}

// Tables
mat-header-row > mat-header-cell.mat-header-cell {
  font-size: 14px;
}

.mat-header-cell, .mat-sort-header-arrow, .mat-header-cell button {
  font-size: 14px;
}

.mat-header-cell button {
  margin-right: 10px;
}

.mat-table {
  margin-top: 20px;
}

.mat-card {
  &.wizard-holder {
    box-shadow: none;
    padding-bottom: 300px; // 120px
    border-top: 1px solid rgba($black, 0.15);
  }
}

// Wizard
.wizard {
  display: flex;
  align-items: flex-start;

  .mat-stepper-horizontal {
    flex: 1 1 auto;
    max-width: 100%;

    .mat-stepper-horizontal {
      .mat-stepper-horizontal-line {
        display: none;
      }
    }
  }

  .mat-stepper-vertical {
    flex: 1 1 auto;
    max-width: 100%;
  }

  .wizard__preview {
    width: 100%;
    height: 280px; // 140px
    padding: 20px;
    background-color: $white;
    border-top: 1px solid rgba($black, 0.15);
    overflow: auto;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9;

    .wizard__preview-text {
      display: flex;
      align-items: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      span {
        font-size: 32px;
        opacity: 0.15;
      }

      .spinner {
        margin-right: 10px;
      }
    }

    .wizard__preview-image {
      height: 100%;
      text-align: center;
      cursor: pointer;

      img {
        height: 100%;
        width: auto;
        max-width: none;
      }
    }
  }
}
 //Filter
.whitelabel-filter .mat-form-field {
  width: 300px;
  margin-right: 10px;
}

.whitelabel-filter .mat-spinner {
  position: relative;
  top: 10px;
  left: 10px;
  visibility: hidden;
}

.whitelabel-filter .mat-spinner.show {
  visibility: visible;
}

.flex-row {
  display: flex;
}

@for $i from 1 through 12 {
  .flex-#{$i} {
    flex: 0 0 calc((#{$i}/12)*100%);
  }
}

@import 'assets/scss/common/print.scss';
