.grid-header {
    .mat-grid-tile .mat-figure {
        justify-content: flex-start;
    }
}

.grid-paginator .mat-paginator-container {
    justify-content: center;
}

.mat-grid-tile.vt-grid-figure .mat-figure {
    align-items: normal;
    justify-content: normal;
    display: block;
}

.vt-grid-figure > div {
    display:flex;
    justify-content: space-between;
    align-items: flex-end;
    height: min-content
}


.vt-grid-figure mat-form-field {
    width:50%;
}

.vt-grid-figure label {
    width: 50%;
}
