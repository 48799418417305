$black: #333;
$white: #fff;
$grey: #757575;
$disabledGrey: lighten($grey, 30%);
$light-grey: rgba(0, 0, 0, 0.03);
$headerColor: lighten($grey, 50%);
$green: #4BB166; // success
$red: #f44336; // danger / faults
$blue: #369ef4; // info
$orange: #F09100; // errors / warnings
$icon-grey: #5a5a5a;
$darkBlue: #283754;

$pt-primary: #50AF64; //vensterGreen
$pt-alternate: #4BB166; //vt-green
$pt-secondary: #FFA500; //vt-orange
$pt-table-header: #005555;
