@import 'colors.scss';

.vt-tab-group {
  .mat-tab-header {
    background-color: $white;
  }

  .vt-tab-title {
    color: $black;
  }

  .vt-tab-form {
    display: flex;
  }

  .vt-textarea {
    width: 50%;
  }
}

.vt-tab-card {
  border-radius: 0;
}

.mat-tab-label {
  opacity: 1;
  color: white;
}

.mat-tab-label-active {
  color: white;
  opacity: 1 !important;
}

.mat-ink-bar {
  color:white;
  border-bottom: 1px solid $headerColor;
}
